// src/theme.js
import { createTheme } from '@mui/material/styles';

// Opret et tilpasset tema
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#111010', // Sætter baggrundsfarven til dark mode
      paper: '#111010',   // Sætter papirfarven til dark mode
    },
    text: {
      primary: '#ffffff', // Sætter primær tekstfarve til hvid
      secondary: '#b0b0b0' // Sætter sekundær tekstfarve til en lysere nuance af grå
    }
    // Du kan tilføje flere tilpasninger her efter behov
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#111010', // Sætter AppBar baggrundsfarve til dark mode
        },
      },
    },
    // Du kan tilpasse andre komponenter her efter behov
  },
});

export default darkTheme;