import React, { useState } from 'react';
import { Container, Grid, Typography, Box, IconButton, Collapse } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import steffenImage from './stfn.jpg'; // Importer dit billede


const Intro = () => {


  return (
    <Container sx={{ marginTop: 4 }}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="h2" component="h1" gutterBottom>
              Hej! 👋
            </Typography>
            <Typography variant="h4" component="h1" gutterBottom>
             Jeg er Steffen Riis
            </Typography>
            <Typography variant="h6" component="p">
              Velkommen til min portfolio.
            </Typography>
            <Typography variant="h6" component="p">
              Jeg er igang med at tage min PBA i webudvikling.
              Kig gerne mine projekter igennem og kontakt mig, hvis du har spørgsmål.
            </Typography>
            <Box mt={2}>
              <Typography variant="h6" component="p">
                Kontakt mig:
              </Typography>
              <Box display="flex" mt={1}>
              <Box display="flex" flexDirection="column" mb={2}>
                <Box display="flex" alignItems="center">
                  <IconButton>
                    <EmailIcon fontSize="large" />
                  </IconButton>

                    <Typography variant="body1" ml={1}>
                      riis@stfnxd.dk
                    </Typography>
                </Box>
              </Box>

              </Box>
              <Box display="flex" flexDirection="column" mb={2}>
                <Box display="flex" alignItems="center">
                <IconButton href="https://www.linkedin.com/in/steffen-riis-9559b116a/" target="_blank">
                  <LinkedInIcon fontSize="large" />
                </IconButton>

                <IconButton href="https://github.com/stfnxd" target="_blank">
                  <GitHubIcon fontSize="large" />
                </IconButton>
                </Box>
              </Box>

              
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="center">
            <img
              src={steffenImage} // Brug den importerede variabel til billedet
              alt="Steffen Riis"
              style={{ width: '100%', maxWidth: '600px', borderRadius: '50%' }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Intro;
