import React from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import Navbar from './components/Navbar';
import ProjectList from './components/ProjectList';
import theme from './components/theme';
import Intro from './components/Intro';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Navbar />
      <Intro />
      <div style={{ padding: '20px' }}>
        <ProjectList />
      </div>
    </ThemeProvider>
  );
}

export default App;