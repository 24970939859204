import React from 'react';
import Grid from '@mui/material/Grid';
import Project from './Project';

const projects = [
  {
    title: 'WebDev 1',
    description: 'Eksamen for første semester af Web Development. Det er et kontrakt oprettelses/afsendelses system',
    tech: 'Vue.js, Pinia, Node.js, SQL',
    link: 'https://github.com/stfnxd/webdev1',
  },
  {
    title: 'WebDev 2',
    description: 'Eksamen for andet semester af Web Development. Dette er et Dashboard, til at se Crypto og Aktier, med data hentet fra en API (Dummy-api inkluderet)',
    tech: 'React.js, Express.js, Node.js, Firebase, MaterialUI, CORS',
    link: 'https://github.com/stfnxd/webdev2',
  },
  {
    title: 'PhP Upload',
    description: 'Eksamens projekt i PHP. Dette er en hjemmeside for Filupload, med link deling så specifikke mennesker har deres eget link',
    tech: 'PHP, Laravel, Filament, Bootstrap',
    link: 'https://github.com/stfnxd/phpUpload',
  },
];

const ProjectList = () => {
  return (
    <Grid container spacing={2}>
      {projects.map((project, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Project {...project} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProjectList;
